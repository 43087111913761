import React from "react";
import { WorkItemDisplay } from "./WorkItemDisplay";

export const WorkItemsContainer = ({ title, workItems, backupMessage }) => {
  return (
    <div>
      <h2 className="text-2xl font-bold">{title}</h2>

      {workItems ? (
        <div className="grid grid-cols-1 gap-8 mt-4">
          {workItems.map(workItem => (
            <WorkItemDisplay {...workItem} />
          ))}
        </div>
      ) : (
        backupMessage
      )}
    </div>
  );
};
