import React from "react";
import ReactMarkdown from "react-markdown";
import { WorkItem } from "../data/workItems";
import Img from "gatsby-image";
import { A } from "./A";

export const WorkItemDisplay = ({
  title,
  techs,
  description,
  role,
  link,
  image,
}: WorkItem) => {
  return (
    <div className="border-l-4 flex-col md:flex-row-reverse border-accent pl-4 flex">
      {image && (
        <Img
          fluid={image.childImageSharp.fluid}
          className="h-20 w-40 self-center flex-shrink-0 mb-4 md:mb-0"
          imgStyle={{ objectFit: "contain" }}
        />
      )}
      <div className="flex-1">
        {link ? (
          <A href={link} target="_blank" className="text-xl">
            {title}
          </A>
        ) : (
          <p className="text-xl">{title}</p>
        )}
        {techs && (
          <p className="text-copy-light text-sm">Techs: {techs.join(", ")}</p>
        )}
        {role && <p className="text-copy-light text-sm">As: {role}</p>}
        <ReactMarkdown className="mt-4 text-base prose text-copy-base">
          {description}
        </ReactMarkdown>
      </div>
    </div>
  );
};
