import React, { useState } from "react";
import { Layout } from "../../components/Layout";
import { WorkItemsContainer } from "../../components/WorkItemsContainer";
import { FilterTypes } from "../../data/workItems";
import { H1 } from "../../components/H1";
import { graphql } from "gatsby";
import { MyEmail } from "../../components/MyEmail";

const FilterTag = ({ isActive, children, onActiveClick, onInactiveClick }) => (
  <button
    className={`rounded-full py-2 px-4 mr-4 border-2 border-accent shadow-md ${
      isActive && "bg-accent"
    }`}
    onClick={isActive ? onActiveClick : onInactiveClick}
  >
    {children}
  </button>
);

export default function Work({ data }) {
  const [filter, setFilter] = useState(null);

  return (
    <Layout pageTitle="Beer's work">
      <H1>
        <span role="img" aria-label="coder">
          🧑‍💻
        </span>{" "}
        My Work
      </H1>

      <p className="mt-4">
        I'm an <b>Engineer</b> with a <b>product background</b> strong in{" "}
        <b>React</b> and <b>Node.js</b>!
      </p>
      <p className="mt-4 mb-8">
        <span role="img" aria-label="exclamation mark">
          ❗️
        </span>{" "}
        Currently open for new opportunities.{" "}
        <span role="img" aria-label="exclamation mark">
          ✉️
        </span>{" "}
        Contact me at <MyEmail />
      </p>

      <div className="invisible h-0 md:h-auto flex items-center md:mt-4 md:visible">
        <div className="mr-4">Show only: </div>
        <FilterTag
          onActiveClick={() => setFilter(null)}
          onInactiveClick={() => setFilter(FilterTypes.asFreelancer)}
          isActive={filter === FilterTypes.asFreelancer}
        >
          as a freelancer
        </FilterTag>
        <FilterTag
          onActiveClick={() => setFilter(null)}
          onInactiveClick={() => setFilter(FilterTypes.asEmployee)}
          isActive={filter === FilterTypes.asEmployee}
        >
          as an employee
        </FilterTag>
        <FilterTag
          onActiveClick={() => setFilter(null)}
          onInactiveClick={() => setFilter(FilterTypes.asHobbyist)}
          isActive={filter === FilterTypes.asHobbyist}
        >
          as a hobbyist
        </FilterTag>
      </div>
      <div className="grid gap-16 mt-8">
        {(filter === null || filter === FilterTypes.asFreelancer) && (
          <WorkItemsContainer
            title="As a Freelancer"
            workItems={data.allFreelancerWorkDataJson.nodes}
          />
        )}

        {(filter === null || filter === FilterTypes.asEmployee) && (
          <WorkItemsContainer
            title="As an Employee"
            workItems={data.allEmployeeWorkDataJson.nodes}
          />
        )}

        {(filter === null || filter === FilterTypes.asHobbyist) && (
          <WorkItemsContainer
            title="As a Hobbyist"
            workItems={data.allHobbyistWorkDataJson.nodes}
          />
        )}
      </div>
    </Layout>
  );
}

export const query = graphql`
  query WorkQuery {
    allFreelancerWorkDataJson {
      nodes {
        description
        link
        role
        techs
        title
        image {
          childImageSharp {
            fluid(maxWidth: 160) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    allEmployeeWorkDataJson {
      nodes {
        description
        link
        role
        techs
        title
        image {
          childImageSharp {
            fluid(maxWidth: 160) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    allHobbyistWorkDataJson {
      nodes {
        description
        link
        techs
        title
        image {
          childImageSharp {
            fluid(maxWidth: 160) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
