import { FixedObject, FluidObject } from "gatsby-image";
import React from "react";

export enum FilterTypes {
  asEmployee = "asEmployee",
  asHobbyist = "asHobbyist",
  asFreelancer = "asFreelancer",
}

export enum Tech {
  React = "React",
  Firestore = "Firestore",
  TypeScript = "TypeScript",
  Gatsby = "Gatsby",
  Tailwind = "Tailwind",
  Unity3D = "Unity3D",
  CSharp = "C#",
  Java = "Java",
  FourierTransform = "Fourier Transformations",
  NodeJS = "Node.js",
  MongoDB = "MongoDB",
  GraphQL = "GraphQL",
  CircleCI = "CircleCI",
  Angular = "Angular",
  Python = "Python",
  Django = "Django",
  PostegreSQL = "PostgreSQL",
}

export interface WorkItem {
  title: string;
  workType: FilterTypes;
  techs?: Tech[];
  description: string;
  link?: string;
  role?: string;
  image?: { childImageSharp: { fluid: FluidObject } };
}
